import React from "react";
import { TransitionLink } from 'gatsby-plugin-transition-link/components/TransitionLink';
import gsap from "gsap";

export default class LinkFade extends React.Component {
  fade = ({ exit: { length }, node, direction }) => {
    const duration = direction === "out" ? length + length / 4 : length;
    const opacity = direction === "in" ? 1 : 0;
    const scrollTop =
      (document.scrollingElement && document.scrollingElement.scrollTop) ||
      document.body.scrollTop ||
      window.pageYOffset;

    const holdPosition =
      direction === "out"
        ? {
          overflowY: "hidden",
          height: "100vh",
          scrollTop: scrollTop
        }
        : {};

    gsap.set(node, holdPosition);
    gsap.fromTo(node, duration, { opacity: !opacity }, { opacity: opacity });
    return gsap;
  };

  render() {
    const {
      duration,
      ...props
    } = this.props;
    const length = duration || 0.8;
    return (
      <TransitionLink
        exit={{
          length: length,
          zIndex: 1,
          trigger: ({ exit, node }) =>
            this.fade({ exit, node, direction: 'out', duration }),
        }}
        entry={{
          length: 0,
          trigger: ({ exit, node }) =>
            this.fade({ exit, node, direction: 'in' }),
        }}
        {...props}>
        {this.props.children}
      </TransitionLink>
    )
  }
}
